import { useEffect } from 'react';
import useUser from '../user/useUser';
import { trackEvent } from './trackEvent';
import { useAnalyticsStore } from './store';

export default function PageViewAnalytics() {
  const { pathname } = useAnalyticsStore.getState();
  const { isLoading } = useUser();
  useEffect(() => {
    if (!isLoading) {
      trackEvent({
        eventName: 'PAGE_VIEWED',
      });
    }
  }, [isLoading, pathname]);
  return null;
}
