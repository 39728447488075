import React, { useMemo } from 'react';
import { Menu, MenuProps, Tooltip } from 'antd';
import NextLink from 'next/link';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigationMenuItem } from '../types';

import classes from './DesktopSideMenu.module.scss';

type MenuItem = Required<MenuProps>['items'][number];

type DesktopSideMenuProps<TId extends string = string> = {
  items: NavigationMenuItem<TId>[];
  className?: string;
  logoHref: string;
};

export default function DesktopSideMenu<TId extends string = string>({
  logoHref,
  items,
  className,
}: DesktopSideMenuProps<TId>) {
  const desktopMenuItems = useMemo(() => items.map((menu) => createDesktopSideMenuItem(menu)), [items]);
  const selectedKeys = useMemo(() => getSelectedKeys(items), [items]);

  return (
    <div className={clsx(classes.sideMenu, className)}>
      <NextLink href={logoHref} className={classes.logo}>
        <Image src="/images/logo.png" width="40" height="40" alt="Market Movers" />
      </NextLink>
      <Menu mode="vertical" items={desktopMenuItems} selectedKeys={selectedKeys} />
    </div>
  );
}

function createDesktopSideMenuItem(menuItem: NavigationMenuItem): MenuItem {
  return {
    key: menuItem.id,
    children: menuItem.isDisabled ? undefined : menuItem.children?.map((item) => createDesktopSideMenuItem(item)),
    label: <DesktopSideMenuItem {...menuItem} />,
    className: clsx({
      [classes.disabled]: menuItem.isDisabled,
    }),
    ...(menuItem.children?.length ? { popupClassName: classes.subMenuItemsPopUp } : {}), // NOTE: not using clsx as antd does not properly handle empty popupClassName
    // NOTE: using antd's disabled prop here breaks mouse interactions and therefore tooltip
  };
}

function DesktopSideMenuItem({
  route,
  isActive,
  isDisabled,
  disabledReason,
  isSubMenuItem,
  icon,
  label,
  Wrapper,
}: NavigationMenuItem) {
  const content = (
    <>
      <FontAwesomeIcon className={classes.icon} icon={icon} fontSize={isSubMenuItem ? 20 : 30} />
      {Array.isArray(label) ? (
        <div className={classes.groupLabel}>
          {label.map((iLabel, index) => (
            <p key={index}>{iLabel}</p>
          ))}
        </div>
      ) : (
        <span>{label}</span>
      )}
    </>
  );
  const className = clsx(classes.menuItem, {
    [classes.selectedMenuItem]: isActive,
    [classes.subMenuItem]: isSubMenuItem,
  });
  if (isDisabled) {
    return (
      <Tooltip placement="right" title={disabledReason}>
        <span className={className}>{content}</span>
      </Tooltip>
    );
  }
  if (Wrapper) {
    return <Wrapper href={route}>{content}</Wrapper>;
  }
  return (
    <NextLink href={route as string} className={className}>
      {content}
    </NextLink>
  );
}

function getSelectedKeys(menuItems: NavigationMenuItem[]): string[] {
  return menuItems.reduce<string[]>((acc, { children, id, isActive }) => {
    if (isActive) {
      acc.push(id);
    }
    if (children?.length) {
      const sleectedChildrenKeys = getSelectedKeys(children);
      acc.push(...sleectedChildrenKeys);
    }
    return acc;
  }, []);
}
