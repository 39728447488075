import type { Collectible, CustomCollectible } from '@sportscardinvestor/schemas';
import { isCustomCollectible } from '@sportscardinvestor/collectible-helpers';
import { type Collectible as OldCollectible } from '../../../sci-ui-components/types/collectible';
import { temp_convertCustomCollectibleToOldCustomCollectible } from './temp_convertCustomCollectibleToOldCustomCollectible';
import { temp_convertCollectibleToOldNonCustomCollectible } from './temp_convertCollectibleToOldNonCustomCollectible';

export function temp_convertCollectibleToOldCollectible(item: Collectible | CustomCollectible): OldCollectible {
  return isCustomCollectible(item)
    ? temp_convertCustomCollectibleToOldCustomCollectible(item)
    : temp_convertCollectibleToOldNonCustomCollectible(item);
}
