import { useMemo } from 'react';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { useCollectible, useCollectibles } from './useCollectible';
import { temp_convertCollectibleToOldCollectible } from './helpers/temp_convertCollectibleToOldCollectible';

/**
 * Please do not use for new featured / rework. Use useCollectible instead
 */
export function useCollectibleOld(
  {
    collectibleType,
    id,
    isPublic: _isPublic = false, // NOTE: we do not care about this anymore
  }: {
    id: number | undefined;
    collectibleType: CollectibleType;
    isPublic?: boolean | undefined;
  },
  options: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  } = {}
) {
  const queryResult = useCollectible(
    {
      collectibleId: id!,
      collectibleType,
    },
    options
  );

  return {
    ...queryResult,
    data: queryResult.data ? temp_convertCollectibleToOldCollectible(queryResult.data) : null,
  };
}

/**
 * Please do not use for new featured / rework. Use useCollectibles instead
 */
export function useCollectiblesOld(
  {
    collectibleType,
    collectibleIds,
  }: {
    collectibleIds: number[];
    collectibleType: CollectibleType;
  },
  options: { enabled?: boolean } = {}
) {
  const result = useCollectibles(
    {
      collectibleIds,
      collectibleType,
    },
    options
  );
  const collectiblesById = result.collectiblesById;

  const collectibles = useMemo(
    () => Object.values(collectiblesById).map(temp_convertCollectibleToOldCollectible),
    [collectiblesById]
  );

  return {
    ...result,
    collectibles,
  };
}
