import { chartColorsList } from '../styles/chartColors';

export type ColorsMap<TKey extends string | number = string> = Map<TKey, string>;
/**
 * Returns new color map based on a list of keys and optional previous color map. Previous color map is used to prevent random color reassignment
 * @param keys
 * @param currentColors
 * @returns newColors
 */
export function getColorsMap<TKey extends string | number = string>(
  keys: TKey[],
  currentColors?: ColorsMap<TKey>
): ColorsMap<TKey> {
  const persistedColors: ColorsMap<TKey> = currentColors
    ? keys.reduce<ColorsMap<TKey>>((acc, key) => {
        const currentColor = currentColors.get(key);
        if (currentColor) {
          acc.set(key, currentColor);
        }
        return acc;
      }, new Map())
    : new Map();

  const usedColors = new Set(persistedColors.values());
  const remainingColors = chartColorsList.filter((color) => !usedColors.has(color));
  const remainingKeys = keys.filter((key) => !persistedColors.has(key));
  const newColors = remainingKeys.reduce((acc, key, index) => {
    acc.set(key, remainingColors[index]);
    return acc;
  }, persistedColors);

  return newColors;
}
