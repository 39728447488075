import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import { useImageDataUrl } from '../useGetImageDataUrl';
import { isInternalImageUrl } from '../helpers/isInternalImageUrl';
import { useCropImageDialogStore } from './store';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';
import ImageCropper from '@/sci-ui-components/forms/ImageCropper/ImageCropper';
import { isDataUrl } from '@/sci-ui-components/utils/image';

const formId = 'CropImageDialog';

export const CropImageDialog = withErrorBoundary(function CropImageDialog() {
  const { close, imageUrl, aspectRatio, autoCropArea, modalWidth, initialCropShift } = useCropImageDialogStore();
  const isOriginalImageCroppable = !!imageUrl && (isDataUrl(imageUrl) || isInternalImageUrl(imageUrl));
  const isExternalImage = !!imageUrl && !isDataUrl(imageUrl) && !isInternalImageUrl(imageUrl);
  const { data: externalImageDataUrl, isLoading } = useImageDataUrl(
    {
      imageURL: imageUrl!,
    },
    {
      enabled: isExternalImage,
    }
  );

  const imageUrlToCrop = isOriginalImageCroppable ? imageUrl : externalImageDataUrl?.base64ImageDataURL ?? null;
  return (
    <ModalDialog
      open={!!imageUrl}
      title={null}
      onCancel={() => close()}
      footer={null}
      variant="primary"
      width={modalWidth ?? 'fit-content'}
    >
      {!isLoading && !imageUrlToCrop && <p className="my-2">Could not load image. Please try again.</p>}
      <ImageCropper
        imageUrl={imageUrlToCrop}
        onSubmit={close}
        formId={formId}
        autoCropArea={autoCropArea}
        aspectRatio={aspectRatio}
        onCancel={() => close()}
        isLoading={isLoading}
        className="max-h-[calc(100vh-160px)]" // NOTE: do not allow cropper to go beyond screen height as it is bad UX
        initialCropShift={initialCropShift}
      />
    </ModalDialog>
  );
});
