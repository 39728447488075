import clsx from 'clsx';
import DefaultCellPadding from '../../Table/presets/DefaultCellPadding';
import CollectibleImage from '../CollectibleImage/CollectibleImage';
import { CollectibleType } from '../../types/collectibleType';
import CommonCollectibleDescription, {
  CommonCollectibleDescriptionProps,
} from '../CommonCollectibleDescription/CommonCollectibleDescription';
import { makeCollectibleDescription } from '../../utils/collectibleDescription';
import { Collectible } from '../../types/collectible';

import classes from './CollectibleTableCell.module.scss';

export type Size = 'regular' | 'small' | 'tiny';

export default function CollectibleTableCell({
  collectible,
  collectibleType,
  size = 'regular',
  hideImage = false,
  className,
  detailsClassName,
  hasCellPadding = true,
  imageClassName,
  ...other
}: {
  collectible: Collectible | null;
  collectibleType: CollectibleType;
  size?: Size;
  hideImage?: boolean;
  detailsClassName?: string;
  hasCellPadding?: boolean;
  imageClassName?: string;
} & CommonCollectibleDescriptionProps) {
  const { title } = makeCollectibleDescription(collectible);
  const Container = hasCellPadding ? DefaultCellPadding : 'div';
  return (
    <Container className={clsx(classes.root, className)}>
      {!hideImage && (
        <CollectibleImage
          alt={title}
          url={collectible?.imageUrl}
          size="small"
          collectibleType={collectibleType}
          className={clsx(
            classes.image,
            {
              [classes.regular]: size === 'regular',
              [classes.small]: size === 'small',
              [classes.tiny]: size === 'tiny',
            },
            imageClassName
          )}
        />
      )}
      <CommonCollectibleDescription
        {...other}
        className={clsx(classes.details, detailsClassName)}
        collectible={collectible}
      />
    </Container>
  );
}
