import { formatISO, parse } from 'date-fns';
import { sortByStringField } from '../../../../utils/sort';
import { ChartData } from '../../../../sci-ui-components/types/chartData';
import { formatDate, getISODatesInRange } from '../../../../sci-ui-components/utils/date';
import { ApiChartData } from '../../../sciApi/types';

export default function chartDataFromApi(apiItems: ApiChartData[], collectibleIds: number[]): ChartData[] {
  if (!apiItems.length) {
    return [];
  }
  const sortedApiItems = sortByStringField(apiItems, (item) => item.sortValue, 'asc');
  const chartDataMap = sortedApiItems.reduce<Record<string, Record<number, ChartData>>>((acc, apiItem) => {
    const date = getApiItemISODateString(apiItem);
    if (!acc[date]) {
      acc[date] = {};
    }
    collectibleIds.forEach((collectibleId) => {
      acc[date][collectibleId] = {
        collectibleId,
        date,
        sortValue: apiItem.sortValue,
        zeroReference: apiItem.zeroReference,
        metrics:
          typeof apiItem[`${collectibleId}_avg`] === 'undefined'
            ? null
            : {
                avgSalePrice: apiItem[`${collectibleId}_avg`],
                totalSalesAmount: apiItem[`${collectibleId}_totalsales`],
                totalSales: apiItem[`${collectibleId}_count`],
                totalAmount: apiItem[`${collectibleId}_total`],
                salesVolume: apiItem[`${collectibleId}_sales_volume`],
                minSalePrice: apiItem[`${collectibleId}_min`],
                maxSalePrice: apiItem[`${collectibleId}_max`],
                avgSalePriceTrend: apiItem[`${collectibleId}_avg_trend`],
                avgSalePriceChangePercentage: apiItem[`${collectibleId}_percentage`],
              },
      };
    });
    return acc;
  }, {});

  const startDate = getApiItemISODateString(sortedApiItems[0]);
  const endDate = getApiItemISODateString(sortedApiItems[sortedApiItems.length - 1]);
  const allDates = getISODatesInRange([startDate, endDate]);

  return allDates.reduce<ChartData[]>((acc, date) => {
    const dateItems = chartDataMap[date];
    collectibleIds.forEach((collectibleId) => {
      const item = dateItems ? dateItems[collectibleId] : null;
      acc.push(
        item ?? {
          date,
          sortValue: `day_${formatDate(date, 'yyyyMMdd')}`,
          collectibleId,
          zeroReference: 0,
          metrics: null,
        }
      );
    });
    return acc;
  }, []);
}

function getApiItemISODateString(apiItem: ApiChartData): string {
  return formatISO(parse(apiItem.name, 'M/d/yyyy', new Date()), { representation: 'date' });
}
