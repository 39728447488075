const pluralRules = new Intl.PluralRules('en-US', {
  type: 'cardinal',
});

type Plurals = Partial<Record<Intl.LDMLPluralRule, string>> & {
  one: string;
  other: string;
};

export function plural(count: number, plurals: Plurals) {
  const rule = pluralRules.select(count);
  return plurals[rule] ?? plurals.other;
}
