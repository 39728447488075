import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  getCollectiblesChartData,
  GetCollectiblesChartDataParams,
  GetCollectiblesChartDataResponse,
} from '../../services/sciApi/charts/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { ChartData } from 'sci-ui-components/types/chartData';
import { IsoDateRange } from 'sci-ui-components/utils/date';

export type { GetCollectiblesChartDataResponse as ChartData } from '../../services/sciApi/charts/index';

type UseChartDataParams = GetCollectiblesChartDataParams;
type Key = ['charts', GetCollectiblesChartDataParams];

export default function useChartData(
  params: UseChartDataParams,
  options: UseQueryOptions<GetCollectiblesChartDataResponse, ApiError, GetCollectiblesChartDataResponse, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResults = useQuery(['charts', params], async ({ signal }) => getCollectiblesChartData(params, signal), {
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
    ...options,
    enabled: (!!isLoggedIn || params.isPublic) && (options?.enabled ?? true),
  });
  return queryResults;
}

export function useChartDataDateRange({ chartData }: { chartData: ChartData[] | null }) {
  return useMemo<IsoDateRange | null>(() => {
    if (!chartData?.length) {
      return null;
    }
    return [chartData[0].date, chartData[chartData.length - 1].date];
  }, [chartData]);
}
