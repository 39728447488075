import React, { useMemo } from 'react';
import { differenceInDays, subDays } from 'date-fns';
import SingleSelect from '../SingleSelect/SingleSelect';
import PillToggles, { PillToggleOption } from '../../buttons/PillToggles/PillToggles';
import useToday from '../../hooks/useToday';
import {
  defaultDaysOptions,
  formatISODate,
  getDateRangeFromPeriodPreset,
  getDaysOptionFromDateRange,
  getEarliestStartDate,
  IsoDateRange,
} from '../../utils/date';
import { useBreakpoint } from 'sci-ui-components/hooks/useBreakpoint';
import { Breakpoint } from 'sci-ui-components/types/breakpoints';

export default function DaysFilter({
  daysOptions = defaultDaysOptions,
  className,
  dateRange,
  dropdownOnBreakpoints,
  onDateChange,
  wide = false,
  noAllOption = false,
  minDate,
  fieldLabel = 'show last',
  dayFilterButtonClassName,
  notEmpty,
}: {
  daysOptions?: number[];
  className?: string;
  dropdownOnBreakpoints?: Breakpoint[] | null;
  onDateChange: (newRange: IsoDateRange) => void;
  dateRange: IsoDateRange;
  wide?: boolean;
  noAllOption?: boolean;
  minDate?: Date | null;
  fieldLabel?: string;
  dayFilterButtonClassName?: string;
  notEmpty?: boolean;
}) {
  const today = useToday();
  const breakpoint = useBreakpoint(true);
  const maxDays = useMemo(() => {
    if (!minDate) {
      return differenceInDays(today, getEarliestStartDate(today));
    }
    return differenceInDays(today, minDate);
  }, [minDate, today]);

  const onDateClick = (daysOption: number) => {
    const start = subDays(today, daysOption);
    onDateChange([formatISODate(start), formatISODate(today)]);
  };
  const onDatePeriodChange = (daysOption: number) => {
    const datePeriodRange = getDateRangeFromPeriodPreset(daysOption);
    onDateChange([formatISODate(datePeriodRange[0]), formatISODate(datePeriodRange[1])]);
  };

  const daysOptionsWithAll = useMemo(
    () => (noAllOption ? daysOptions : [...daysOptions, maxDays]),
    [daysOptions, noAllOption, maxDays]
  );

  const presetOptions = useMemo<PillToggleOption<number>[]>(() => {
    let options = daysOptions.map<PillToggleOption<number>>((days) => ({
      label: `${days} days`,
      value: days,
      disabled: maxDays < days,
    }));
    if (!noAllOption) {
      options.push({
        label: 'All data',
        value: maxDays,
      });
    }
    return options;
  }, [daysOptions, noAllOption, maxDays]);

  const currentOption = useMemo(
    () => getDaysOptionFromDateRange(dateRange, daysOptionsWithAll, today),
    [dateRange, daysOptionsWithAll, today]
  );

  return (
    <div>
      {dropdownOnBreakpoints && breakpoint && dropdownOnBreakpoints.find((item) => item === breakpoint) ? (
        <div>
          <SingleSelect
            options={presetOptions}
            fieldLabel={fieldLabel}
            fieldLabelPlacement="inline"
            value={currentOption}
            onChange={(e) => {
              if (!!e) {
                onDatePeriodChange(e);
              }
            }}
            notEmpty={notEmpty}
          />
        </div>
      ) : (
        <PillToggles
          className={className}
          options={presetOptions}
          value={currentOption}
          onChange={onDateClick}
          labelText={fieldLabel}
          dense={!wide}
          buttonClassName={dayFilterButtonClassName}
        />
      )}
    </div>
  );
}
