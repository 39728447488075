export default function unique<TItem, TKey = TItem>(
  items: TItem[],
  getKey: (item: TItem) => TKey = (v) => v as unknown as TKey
): TItem[] {
  const set = new Set<TKey>();
  return items.reduce<TItem[]>((acc, item) => {
    const key = getKey(item);
    if (!set.has(key)) {
      acc.push(item);
    }
    set.add(key);
    return acc;
  }, []);
}
