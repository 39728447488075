import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';

import classes from './PillToggles.module.scss';

export interface PillToggleOption<TValue extends string | number = string> {
  label: string;
  value: TValue;
  disabled?: boolean;
}

export interface PillTogglesProps<TValue extends string | number = string> {
  options: PillToggleOption<TValue>[];
  className?: string;
  buttonClassName?: string;
  activeClassName?: string;
  onChange: (value: TValue) => void;
  value: TValue | null;
  labelText?: string;
  dense?: boolean;
  disabled?: boolean;
}

export default function PillToggles<TValue extends string | number = string>({
  className,
  buttonClassName,
  activeClassName,
  onChange,
  options,
  value,
  labelText,
  dense = false,
  disabled,
}: PillTogglesProps<TValue>) {
  return (
    <div className={clsx(classes.root, { [classes.dense]: dense, [classes.wide]: !dense }, className)}>
      {!!labelText && <span className={classes.hint}>{labelText}</span>}
      {options.map((option) => (
        <Button
          key={option.value}
          size="small"
          shape="round"
          className={clsx(
            classes.button,
            buttonClassName,
            { [classes.buttonSelected]: value === option.value },
            activeClassName ? { [activeClassName]: value === option.value } : null
          )}
          onClick={disabled ? undefined : () => onChange(option.value)}
          disabled={disabled || option.disabled}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}
