import { ReactNode } from 'react';
import { Radio as AntRadio, RadioProps as AntRadioProps } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import clsx from 'clsx';

import classes from './Radios.module.scss';

export type RadioProps = AntRadioProps;
export type RadioSize = 'big' | 'small';

export function Radio({ className, checked, size = 'big', ...props }: RadioProps & { size?: RadioSize }) {
  return (
    <AntRadio
      className={clsx(
        classes.radio,
        { [classes.checked]: checked, [classes.big]: size === 'big', [classes.small]: size === 'small' },
        className
      )}
      checked={checked}
      {...props}
    />
  );
}

export interface RadioOption<TValue extends string | number> {
  value: TValue;
  label: ReactNode;
}

export interface RadiosProps<TValue extends string | number> {
  options: RadioOption<TValue>[];
  selectedValues: TValue[];
  onChange?: (newSelectedValues: TValue[]) => void;
  className?: string;
  disabled?: boolean;
  columns?: number;
  minItemWidth?: number;
  size?: RadioSize;
}

export function Radios<TValue extends string | number>({
  className,
  onChange,
  options,
  selectedValues,
  disabled,
  columns = 1,
  minItemWidth = 212,
  size,
}: RadiosProps<TValue>) {
  const handleCheck = (e: RadioChangeEvent) => {
    const value = e.target.value as TValue;
    if (onChange) {
      onChange([value]);
    }
  };
  return (
    <div className={clsx(classes.radiosGroup, className)}>
      {options.map(({ label, value }) => {
        return (
          <Radio
            value={value}
            className={classes.radiosItem}
            key={value}
            checked={selectedValues.includes(value)}
            onChange={handleCheck}
            disabled={disabled}
            style={{
              flexBasis: `calc(100%/${columns} - 16px)`,
              minWidth: minItemWidth ?? undefined,
            }}
            size={size}
          >
            {label}
          </Radio>
        );
      })}
    </div>
  );
}
