import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import {
  Collectible,
  NonCustomSportsCardCollectible,
  NonCustomSealedWaxCollectible,
  UnknownCollectible,
} from '../../../../sci-ui-components/types/collectible';
import { ApiCollectible, ApiCollectibleSealedWax, ApiCollectibleSportsCard } from '../../types';
import collectibleHistoricalStatsFromApi from '../../stats/transformers/collectibleHistoricalStatsFromApi';
import cardSetFromApi from './cardSetFromApi';
import cardGradeFromApi from './cardGradeFromApi';
import isRookie from './isRookie';
import playerFromApi from './playerFromApi';
import cardPhotoFromApi from './cardPhotoFromApi';
import cardSetVariationFromApi from './cardSetVariationFromApi';
import cardVariationFromApi from './cardVariationFromApi';
import sealedWaxBoxtypeFromApi from './sealedWaxBoxtypeFromApi';

export default function collectibleFromApi(apiItem: ApiCollectible, collectibleType: CollectibleType): Collectible {
  switch (collectibleType) {
    case 'sports-card': {
      const sportsCardApiItem = apiItem as ApiCollectibleSportsCard;
      const cardSet = cardSetFromApi(sportsCardApiItem.card_set);
      const player = playerFromApi(sportsCardApiItem.player);

      const result: NonCustomSportsCardCollectible = {
        cardNumber: stringOrNull(sportsCardApiItem.card_number),
        cardSet,
        cardSetId: sportsCardApiItem.set_id,
        collectibleType,
        createdAt: sportsCardApiItem.created_at,
        createdById: sportsCardApiItem.createdById,
        grade: cardGradeFromApi(sportsCardApiItem.grade),
        gradeId: sportsCardApiItem.grade_id,
        historicalStats: sportsCardApiItem.historical_stats
          ? collectibleHistoricalStatsFromApi(sportsCardApiItem.historical_stats, collectibleType)
          : null,
        id: sportsCardApiItem.id,
        imageUrl: sportsCardApiItem.photo?.image_url ?? sportsCardApiItem.image_url,
        adminImageUrl: sportsCardApiItem.image_url,
        isCustom: false,
        isRookie: isRookie(cardSet, player),
        lastLiveSyncedAt: sportsCardApiItem.last_live_synced_at,
        lastSyncedAt: sportsCardApiItem.last_synced_at,
        photo: cardPhotoFromApi(sportsCardApiItem.photo),
        player,
        playerId: sportsCardApiItem.player_id,
        populationCount: sportsCardApiItem.population_count,
        populationLastUpdated: sportsCardApiItem.population_last_updated,
        query: sportsCardApiItem.query,
        queryId: sportsCardApiItem.card_query_id,
        serialNumber: stringOrNull(sportsCardApiItem.serial_number),
        setVariation: cardSetVariationFromApi(sportsCardApiItem.set_variation),
        setVariationId: sportsCardApiItem.set_variation_id,
        specificQualifier: sportsCardApiItem.specific_qualifier,
        updatedAt: sportsCardApiItem.updated_at,
        updatedById: sportsCardApiItem.updatedById,
        variation: cardVariationFromApi(sportsCardApiItem.variation),
        variationId: sportsCardApiItem.variation_id,
      };
      return result;
    }
    case 'sealed-wax-card': {
      const waxApiItem = apiItem as ApiCollectibleSealedWax;
      const result: NonCustomSealedWaxCollectible = {
        boxType: sealedWaxBoxtypeFromApi(waxApiItem.box_type),
        boxTypeId: waxApiItem.box_type_id,
        cardSet: cardSetFromApi(waxApiItem.card_set),
        cardSetId: waxApiItem.set_id,
        collectibleType,
        createdAt: waxApiItem.created_at,
        createdById: waxApiItem.created_by_id,
        historicalStats: waxApiItem.sealed_wax_stats
          ? collectibleHistoricalStatsFromApi(waxApiItem.sealed_wax_stats, collectibleType)
          : null,
        id: waxApiItem.id,
        imageUrl: waxApiItem.photo?.image_url ?? waxApiItem.image_url,
        adminImageUrl: waxApiItem.image_url,
        isCustom: false,
        lastLiveSyncedAt: waxApiItem.last_live_synced_at,
        lastSyncedAt: waxApiItem.last_synced_at,
        query: waxApiItem.query,
        queryId: waxApiItem.card_query_id,
        updatedAt: waxApiItem.updated_at,
        updatedById: waxApiItem.updated_by_id,
      };
      return result;
    }
    default: {
      const result: UnknownCollectible = {
        collectibleType,
        createdAt: apiItem.created_at,
        createdById: null,
        id: apiItem.id,
        imageUrl: apiItem.image_url,
        isCustom: false,
        query: apiItem.query,
        updatedAt: apiItem.updated_at,
        updatedById: null,
      };
      return result;
    }
  }
}

function stringOrNull(v: any) {
  return v ? String(v) : null;
}
