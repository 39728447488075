import { startOfWeek, startOfMonth, parseISO } from 'date-fns';
import { DateRangeDaysCount, formatISODate } from '../../../sci-ui-components/utils/date';
import { ChartData, CollectibleChartMetrics } from '../../../sci-ui-components/types/chartData';
import { sortByStringField } from '../../../utils/sort';
import { GroupOption } from '../useChartSettings';

export type ChartDataGrouped = Omit<ChartData, 'metrics' | 'collectibleId'> & {
  metricsByCollectibleId: Record<number, AggregatedMetrics>;
  specificPointsById?: Record<number, SpecificPoint>;
};

export interface SpecificPoint {
  salePrice: number;
  title: string;
  date: string;
  id: number;
  color?: string;
}

type AggregatedMetrics = CollectibleChartMetrics & {
  aggregatedItemsCount: number;
  aggregatedAvgSalePriceSum: number;
  aggregatedAvgSalePriceChangePercentageSum: number;
};

export function groupChartData(
  data: ChartData[] | undefined,
  specificPoints: SpecificPoint[] | undefined,
  groupBy: GroupOption
): ChartDataGrouped[] {
  const dataByDateMap = (data ?? []).reduce<Map<string, ChartDataGrouped>>((acc, item) => {
    const { metrics, collectibleId, zeroReference } = item;
    const groupKey = getGroupValue(item.date, groupBy);
    if (!acc.has(groupKey)) {
      const initialGroup: ChartDataGrouped = {
        date: groupKey,
        sortValue: groupKey,
        zeroReference,
        metricsByCollectibleId: {},
      };
      if (metrics) {
        initialGroup.metricsByCollectibleId[collectibleId] = makeInitialAggregatedMetrics(metrics);
      }
      acc.set(groupKey, initialGroup);
    } else {
      const group = acc.get(groupKey)!;
      if (metrics) {
        const currentMetrics = group.metricsByCollectibleId[item.collectibleId];
        const newMetrics = currentMetrics
          ? aggregateMetrics(currentMetrics, metrics)
          : makeInitialAggregatedMetrics(metrics);
        acc.get(groupKey)!.metricsByCollectibleId[item.collectibleId] = newMetrics;
      }
    }
    return acc;
  }, new Map());

  // NOTE: add specific points
  specificPoints?.forEach((specificPoint) => {
    const groupKey = getGroupValue(specificPoint.date, groupBy);
    if (!dataByDateMap.has(groupKey)) {
      dataByDateMap.set(groupKey, {
        date: groupKey,
        sortValue: groupKey,
        zeroReference: 0,
        metricsByCollectibleId: {},
        specificPointsById: {
          [specificPoint.id]: specificPoint,
        },
      });
    } else {
      const group = dataByDateMap.get(groupKey)!;
      group.specificPointsById = group.specificPointsById ?? {};
      group.specificPointsById[specificPoint.id] = specificPoint;
    }
  });

  const dataByDate = Array.from(dataByDateMap.values());
  return sortByStringField(dataByDate, (dataByDate) => dataByDate.sortValue);
}

function getGroupValue(date: string, groupBy: GroupOption): string {
  switch (groupBy) {
    case 'day':
      return date;
    case 'month':
      return formatISODate(startOfMonth(parseISO(date)));
    case 'week':
      return formatISODate(startOfWeek(parseISO(date)));
    default:
      return date;
  }
}

function makeInitialAggregatedMetrics(item: CollectibleChartMetrics): AggregatedMetrics {
  return {
    ...item,
    aggregatedItemsCount: 1,
    aggregatedAvgSalePriceSum: item.avgSalePrice,
    aggregatedAvgSalePriceChangePercentageSum: item.avgSalePriceChangePercentage,
  };
}

function aggregateMetrics(acc: AggregatedMetrics, item: CollectibleChartMetrics): AggregatedMetrics {
  const aggregatedAvgSalePriceChangePercentageSum =
    acc.aggregatedAvgSalePriceChangePercentageSum + item.avgSalePriceChangePercentage;
  const aggregatedAvgSalePriceSum = acc.aggregatedAvgSalePriceSum + item.avgSalePrice;
  const aggregatedItemsCount = acc.aggregatedItemsCount + 1;
  return {
    aggregatedAvgSalePriceChangePercentageSum,
    aggregatedAvgSalePriceSum,
    aggregatedItemsCount,
    avgSalePrice: aggregatedAvgSalePriceSum / aggregatedItemsCount,
    avgSalePriceChangePercentage: aggregatedAvgSalePriceChangePercentageSum / aggregatedItemsCount,
    maxSalePrice: Math.max(acc.maxSalePrice, item.maxSalePrice),
    minSalePrice: Math.min(acc.minSalePrice, item.minSalePrice),
    salesVolume: acc.salesVolume + item.salesVolume,
    totalAmount: acc.totalAmount + item.totalAmount,
    totalSales: acc.totalSales + item.totalSales,
    totalSalesAmount: acc.totalSalesAmount + item.totalSalesAmount,
    avgSalePriceTrend: acc.avgSalePriceTrend ?? item.avgSalePriceTrend,
  };
}

export const makeAverageSaleLabel = (dateRangeDays: DateRangeDaysCount) => `Avg ${getDateRangeLabel(dateRangeDays)}`;

export const getDateRangeLabel = (dateRangeDays: DateRangeDaysCount) =>
  `(${dateRangeDays === 'all' ? 'All' : dateRangeDays} days)`;
