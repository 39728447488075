import { useEffect, useMemo, useRef } from 'react';
import { getColorsMap, ColorsMap } from '../utils/colors';

/**
 * Returns color map based on a list of keys. Colors are not re-assigned for keys that did not appear/disappear when other keys change
 * @param keys
 * @returns
 */
export default function useColorsMap<TKey extends string | number = string>(keys: TKey[]) {
  const colorsMapRef = useRef<ColorsMap<TKey>>();
  const colorsMap = useMemo(() => getColorsMap(keys, colorsMapRef.current), [keys]);
  useEffect(() => {
    colorsMapRef.current = colorsMap;
  }, [colorsMap]);

  return colorsMap;
}
