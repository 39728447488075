import React, { useMemo } from 'react';
import NextLink from 'next/link';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import Button from '../../../sci-ui-components/buttons/Button/Button';
import useColorsMap from '../../../sci-ui-components/hooks/useColorsMap';
import useDateRangeState from '../../../hooks/useDateRangeState';
import Charts from '../Charts/Charts';
import useChartData, { useChartDataDateRange } from '../useChartData';
import useAdvancedChartFilters from '../useAdvancedChartFilters';
import { generateCollectiblesChartHref } from '../../search/utils/collectibleChart';
import { ChartsDialogProps } from './types';

import classes from './ChartsDialog.module.scss';

export default function ChartsDialog({
  collectibleIds = [],
  collectibleType = 'sports-card',
  isOpen,
  onClose,
  dateRange: defaultDateRange,
  customDateRange,
  specificPoints,
}: ChartsDialogProps & {
  isOpen: boolean;
  onClose: (hasGoneToCharts: boolean) => void;
}) {
  const isEmpty = !collectibleIds.length || !collectibleType;
  const colorsMapIds = useMemo(
    () => collectibleIds.concat(specificPoints?.map((v) => v.id) ?? []),
    [collectibleIds, specificPoints]
  );
  const colors = useColorsMap(colorsMapIds);

  const { advancedFilters } = useAdvancedChartFilters({ collectibleType });

  const [dateRange, setDateRange] = useDateRangeState(defaultDateRange, customDateRange);

  const { data, isLoading } = useChartData(
    {
      collectibleIds,
      dateRange,
      advancedFilters,
      collectibleType,
    },
    {
      enabled: !isEmpty,
    }
  );
  const chartedDateRange = useChartDataDateRange({ chartData: data?.chartData ?? [] });

  const visible = isOpen && !isEmpty;

  const chartHref = generateCollectiblesChartHref({
    collectibleIds,
    collectibleType: collectibleType!,
    isoDateRange: dateRange,
  });

  return (
    <ModalDialog
      visible={visible}
      title={
        <NextLink href={chartHref} legacyBehavior>
          <Button
            onClick={() => {
              onClose(true);
            }}
            href={chartHref}
            type="primary"
            className={classes.button}
          >
            View on Charts
          </Button>
        </NextLink>
      }
      titleAlign="right"
      footer={null}
      width="100%"
      onCancel={() => onClose(false)}
      dense
      centered
    >
      <Charts
        collectibleType={collectibleType}
        collectibleIds={collectibleIds ?? []}
        collectibleStats={data?.collectibleStats ?? null}
        collectibles={data?.collectibles ?? []}
        data={data?.chartData ?? []}
        specificPoints={specificPoints}
        dateRange={dateRange}
        chartedDateRange={chartedDateRange}
        colors={colors}
        isLoading={isLoading}
        onDateRangeChange={setDateRange}
        noFullScreen
        alwaysShowLegend
      />
    </ModalDialog>
  );
}
