import { CardVariation } from '../../../../sci-ui-components/types/cardVariation';
import { ApiCardVariation } from '../../types';

export default function cardVariationFromApi(apiVariation: ApiCardVariation): CardVariation {
  return {
    id: apiVariation.id,
    name: apiVariation.name,
    query: apiVariation.query,
    deprecated: apiVariation.deprecated,
  };
}
