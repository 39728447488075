import React, { ReactNode } from 'react';
import clsx from 'clsx';
import classes from './FormFieldsRow.module.scss';

export interface FormFieldsRowProps {
  className?: string;
  children: ReactNode;
  isSingleItemRow?: boolean;
  align?: 'start' | 'end';
}

export default function FormFieldsRow({
  className,
  children,
  isSingleItemRow = false,
  align = 'end',
}: FormFieldsRowProps) {
  return (
    <div
      className={clsx(classes.root, className, isSingleItemRow && classes.singleItemRow, {
        [classes.alignStart]: align === 'start',
        [classes.alignEnd]: align === 'end',
      })}
    >
      {children}
    </div>
  );
}
