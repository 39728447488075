import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface LayoutControlState {
  isHeaderVisible: boolean;
  setIsHeaderVisible: (value: boolean) => void;
}

const useLayoutControlState = create<LayoutControlState>()(
  devtools(
    (set) => ({
      isHeaderVisible: true,
      setIsHeaderVisible: (value) => set({ isHeaderVisible: value }),
    }),
    {
      name: 'layout-control-state',
    }
  )
);

export default useLayoutControlState;
