import { useFlags } from 'launchdarkly-react-client-sdk';

export type AccessFlag =
  | 'isHeritageFilterAvailable'
  | 'isUserPreferenceVisible'
  | 'isMySlabsFilterAvailable'
  | 'isAuctionsTabAvailable';

/**
 * Returns access feature flag boolean.
 **/
export default function useAccessFlags({ flag }: { flag: AccessFlag }) {
  const { [flag]: accessValue } = useFlags();

  return accessValue;
}
