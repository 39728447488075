import { useEffect } from 'react';
import useFullScreenState from '../../sci-ui-components/FullScreen/useFullScreenState';
import { trackEvent } from '../analytics/trackEvent';

function useChartFullScreenState(): [boolean, () => void];
function useChartFullScreenState(noFullScreen: boolean): [boolean, undefined | (() => void)];
function useChartFullScreenState(noFullScreen?: boolean): [boolean, undefined | (() => void)] {
  const result = useFullScreenState(noFullScreen ?? false);
  const [isFullScreen] = result;
  useEffect(() => {
    if (isFullScreen) {
      trackEvent({
        eventName: 'CHARTS_FULLSCREEN_TOGGLED_ON',
      });
    }
  }, [isFullScreen]);
  return result;
}

export default useChartFullScreenState;
