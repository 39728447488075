import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { formatDateRange, MaybeIsoDate } from '../utils/date';
import ClearIconButton from '../forms/ClearIconButton/ClearIconButton';
import classes from './DateRangeSelectorButton.module.scss';

export type DateRangeSelectorButtonProps = {
  className?: string;
  startDate: MaybeIsoDate;
  endDate: MaybeIsoDate;
  onClick: () => void;
  onClear?: () => void;
  disabled?: boolean;
  labelText?: string;
  id?: string;
  dateRangeFormatStr?: string;
  contentClassName?: string;
};

export default function DateRangeSelectorButton({
  className,
  endDate,
  startDate,
  labelText = 'Period',
  onClick,
  onClear,
  dateRangeFormatStr,
  disabled,
  id,
  contentClassName,
}: DateRangeSelectorButtonProps) {
  return (
    <div className={clsx(classes.button, className)} onClick={!disabled ? onClick : () => {}} id={id}>
      <FontAwesomeIcon icon={faCalendar} className={classes.icon} />
      <div className={clsx(classes.content, contentClassName)}>
        <span className={classes.label}>{labelText}</span>
        <span className={classes.selectedRange}>
          {formatDateRange({
            startDate,
            endDate,
            formatStr: dateRangeFormatStr,
          })}
        </span>
        <span>
          <ClearIconButton onClick={() => onClear?.()} visible={!!onClear && !!endDate && !!startDate} />
        </span>
      </div>
    </div>
  );
}
