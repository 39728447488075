import { useMemo } from 'react';
import { faGear } from '@fortawesome/pro-light-svg-icons';
import ExtraActionsDropdown from '../../../sci-ui-components/ExtraActionsDropdown/ExtraActionsDropdown';
import { Radio } from '../../../sci-ui-components/forms/Radios/Radios';
import {
  ChartSettings,
  GroupOption,
  YBaselineOption,
  ShowTrendOption,
  SetGroupByFn,
  SetYBaselineFn,
  SetTrendFn,
} from '../useChartSettings';

export interface ChartSetting<TSettingId extends string = string, TItemId extends string = string> {
  id: TSettingId;
  label: string;
  selectedItemId: TItemId;
  items: ChartSettingItem<TItemId>[];
}

export interface ChartSettingItem<TItemId extends string = string> {
  id: TItemId;
  label: string;
  onSelect: () => void;
  isDisabled?: boolean;
}

const groupByNames: Record<GroupOption, string> = {
  day: 'Result by Day',
  week: 'Result by Week',
  month: 'Result by Month',
};

export interface ChartSettingsDropdownProps {
  appendSettings?: ChartSetting[];
  chartSettings: ChartSettings;
  setTrend: SetTrendFn;
  setYBaseline: SetYBaselineFn;
  setGroupBy: SetGroupByFn;
}

export default function ChartSettingsDropdown({
  appendSettings,
  chartSettings,
  setTrend,
  setYBaseline,
  setGroupBy,
}: ChartSettingsDropdownProps) {
  const settings = useMemo<ChartSetting[]>(() => {
    const baseSettings: [
      ChartSetting<string, GroupOption>,
      ChartSetting<string, ShowTrendOption>,
      ChartSetting<string, YBaselineOption>
    ] = [
      {
        id: 'group',
        label: 'Results by Timespan',
        selectedItemId: chartSettings.groupBy,
        items: chartSettings.groupByOptions.map(({ value, isDisabled }) => ({
          id: value,
          label: groupByNames[value],
          onSelect: () => setGroupBy(value),
          isDisabled,
        })),
      },
      {
        id: 'trend-line',
        label: 'Trend Line',
        selectedItemId: chartSettings.showTrend,
        items: [
          {
            id: 'line',
            label: 'Show Sales Only',
            onSelect: () => setTrend('line'),
          },
          {
            id: 'trend',
            label: 'Show Trend Only',
            onSelect: () => setTrend('trend'),
          },
          {
            id: 'both',
            label: 'Show Trend and Sales',
            onSelect: () => setTrend('both'),
          },
        ],
      },
      {
        id: 'yBaseline',
        label: 'Y Axis',
        selectedItemId: chartSettings.yBaseline,
        items: [
          {
            id: 'min',
            label: 'Start Graph at Low Sale',
            onSelect: () => setYBaseline('min'),
          },
          {
            id: 'zero',
            label: 'Start Graph at $0',
            onSelect: () => setYBaseline('zero'),
          },
        ],
      },
    ];
    return [...(appendSettings ?? []), ...baseSettings];
  }, [chartSettings, setTrend, setYBaseline, setGroupBy, appendSettings]);

  return (
    <ExtraActionsDropdown
      faIcon={faGear}
      items={settings.map((group) => ({
        groupLabel: group.label,
        id: group.id,
        items: group.items.map((item) => ({
          id: `${group.id}-${item.id}`,
          label: item.label,
          Icon: <Radio checked={group.selectedItemId === item.id} size="small" />,
          onSelect: item.onSelect,
          disabled: item.isDisabled,
        })),
      }))}
    />
  );
}
