import { ReactNode, useMemo } from 'react';
import TimeLineChart, { LineConfig } from '../../../sci-ui-components/charts/TimeLineChart/TimeLineChart';
import { Collectible } from '../../../sci-ui-components/types/collectible';
import { ColorsMap } from '../../../sci-ui-components/utils/colors';
import { CollectibleChartMetrics } from '../../../sci-ui-components/types/chartData';
import { plural } from '../../../sci-ui-components/utils/pluralize';
import {
  getSportsCardGrade,
  makeCollectibleDescription,
} from '../../../sci-ui-components/utils/collectibleDescription';
import { StatType } from '../../../sci-ui-components/types/statType';
import { ChartSettings } from '../useChartSettings';
import { ChartDataGrouped, SpecificPoint } from './utils';
import classes from './Charts.module.scss';
import CollectibleImage from 'sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import GradeIndicator from 'sci-ui-components/sport-cards/GradeIndicator/GradeIndicator';
import useFormatStatValue, { FormatStatValueFn } from 'hooks/useFormatStatValue';

export type ChartValueField = keyof CollectibleChartMetrics;

export default function LineChart({
  className,
  isLoading,
  header,
  chartSettingsControls,
  highlightedCollectibleId,
  data,
  specificPoints,
  chartValueField,
  chartValueTrendField,
  chartValueType,
  collectibles,
  colors,
  isFullScreen,
  toggleFullScreen,
  emptyMessage,
  chartSettings,
  height = 400,
  headerSectionClassName,
  chartsWrapperClassName,
}: {
  className?: string;
  isLoading: boolean;
  header: ReactNode;
  chartSettingsControls: ReactNode;
  highlightedCollectibleId: number | undefined | null;
  data: ChartDataGrouped[];
  specificPoints?: SpecificPoint[];
  chartValueField: ChartValueField;
  chartValueTrendField?: ChartValueField;
  chartValueType: StatType;
  collectibles: Collectible[];
  colors: ColorsMap<number>;
  isFullScreen?: boolean;
  toggleFullScreen?: () => void;
  emptyMessage: string;
  chartSettings: ChartSettings;
  height?: number;
  headerSectionClassName?: string;
  chartsWrapperClassName?: string;
}) {
  const { formatStatValue } = useFormatStatValue();

  const chartLines = useMemo(() => {
    const lines = collectibles.reduce<LineConfig<ChartDataGrouped, number>[]>((acc, collectible) => {
      const { fullDescription } = makeCollectibleDescription(collectible);
      acc.push({
        dataKey: (item) => {
          const metrics = item.metricsByCollectibleId[collectible.id];
          return metrics ? metrics[chartValueField] : null;
        },
        label: fullDescription,
        color: colors.get(collectible.id) ?? '',
        key: collectible.id,
        strokeWidth: 3,
        renderPreviewInTooltip: (item) => renderPreviewInTooltip(item, collectible),
        renderStatsInTooltip: (item) => renderStatsInTooltip(item, fullDescription, collectible, formatStatValue),
      });
      if (chartValueTrendField) {
        acc.push({
          dataKey: (item) => {
            const metrics = item.metricsByCollectibleId[collectible.id];
            return metrics ? metrics[chartValueTrendField] : null;
          },
          label: `${fullDescription} trend`,
          color: colors.get(collectible.id) ?? '',
          key: collectible.id,
          isTrend: true,
          strokeWidth: 3,
          renderPreviewInTooltip: (item) => renderPreviewInTooltip(item, collectible),
          renderStatsInTooltip: (item) => renderStatsInTooltip(item, fullDescription, collectible, formatStatValue),
        });
      }
      return acc;
    }, []);

    // NOTE: add specific points
    specificPoints?.forEach(({ id, title, color }) => {
      lines.push({
        dataKey: (item) => {
          return item.specificPointsById?.[id]?.salePrice ?? null;
        },
        label: title,
        color: color ?? colors.get(id) ?? '',
        key: id,
        strokeWidth: 7,
        renderStatsInTooltip: (item) => renderSpecificPointInTooltip(item, id, formatStatValue),
      });
    });

    return lines;
  }, [collectibles, specificPoints, chartValueField, colors, chartValueTrendField, formatStatValue]);

  return (
    <TimeLineChart
      className={className}
      isLoading={isLoading}
      data={data || []}
      lines={chartLines}
      height={height}
      formatYAxisValue={(value) =>
        formatStatValue({
          value,
          type: chartValueType,
        })
      }
      xAxisDataKey="date"
      chartHeader={header}
      chartSettings={chartSettingsControls}
      highlighDataKey={highlightedCollectibleId}
      connectNulls
      withDots
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
      emptyMessage={emptyMessage}
      hideNonTrendLines={chartSettings.showTrend === 'trend' && !!chartValueTrendField}
      hideTrendLines={chartSettings.showTrend === 'line'}
      yAxisStartFromMin={chartSettings.yBaseline === 'min'}
      yAxisAllowDecimals={chartValueType !== 'count'}
      headerSectionClassName={headerSectionClassName}
      chartsWrapperClassName={chartsWrapperClassName}
    />
  );
}

function renderStatsInTooltip(
  item: ChartDataGrouped,
  description: string,
  collectible: Collectible,
  formatStatValue: FormatStatValueFn
) {
  const metrics = item.metricsByCollectibleId[collectible.id];
  if (!metrics) {
    return null;
  }
  return (
    <>
      <span className={classes.tooltipStat1}>
        {formatStatValue({
          value: metrics.totalSales,
          type: 'count',
        })}{' '}
        {plural(metrics.totalSales, {
          one: 'Sale',
          other: 'Sales',
        })}
      </span>
      <span className={classes.tooltipStat2}>
        Avg:{' '}
        {formatStatValue({
          value: metrics.avgSalePrice,
          type: 'price',
        })}
        {` (${formatStatValue({
          value: metrics.avgSalePriceChangePercentage,
          type: 'percentage',
        })})`}
      </span>
      <GradeIndicator grade={getSportsCardGrade(collectible)} />
    </>
  );
}

function renderSpecificPointInTooltip(item: ChartDataGrouped, id: number, formatStatValue: FormatStatValueFn) {
  const salePrice = item.specificPointsById?.[id]?.salePrice;
  return (
    <span className={classes.tooltipStat1}>
      {formatStatValue({
        value: salePrice,
        type: 'price',
      })}
    </span>
  );
}

function renderPreviewInTooltip(item: ChartDataGrouped, collectible: Collectible) {
  return (
    <>
      <CollectibleImage
        collectibleType={collectible.collectibleType}
        url={collectible.imageUrl}
        alt={''}
        size="small"
      />
    </>
  );
}
