import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import ConfirmationDialog from './ConfirmationDialog';
import { registerDialog, ConfirmationDialogProviderProps } from './index';

type State = Partial<ConfirmationDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function ConfirmationDialogProvider({ id }: { id: string }) {
  const [
    { isOpen, cancelText, confirmText, description, resolve, title, onConfirm, onCancel, requireTextInput },
    setState,
  ] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
      }),
    [id]
  );

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={title}
      description={description}
      requireTextInput={requireTextInput}
      confirmText={confirmText}
      cancelText={cancelText}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onConfirm={async () => {
        await onConfirm?.();
        resolve?.(true);
      }}
      onCancel={() => {
        onCancel?.();
        resolve?.(false);
      }}
    />
  );
});
