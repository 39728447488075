import { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCopy, faSquare } from '@fortawesome/pro-solid-svg-icons';
import IconButton, { IconButtonSize } from '../../buttons/IconButton/IconButton';

import classes from './ChartedCollectibleIndicator.module.scss';
import { AlternateGrade } from 'sci-ui-components/sport-cards/GradeIndicator/GradeIndicator';

export interface ChartedCollectibleIndicatorProps {
  alternateGrades?: AlternateGrade[] | null;
  className?: string;
  isHidden?: boolean;
  onToggleHidden?: () => void;
  onDelete?: () => void;
  onDuplicate?: (alternateGrade: AlternateGrade) => void;
  color: string | undefined;
  size?: IconButtonSize;
}

export default function ChartedCollectibleIndicator({
  alternateGrades,
  className,
  color,
  isHidden,
  onDelete,
  onDuplicate,
  onToggleHidden,
  size,
}: ChartedCollectibleIndicatorProps) {
  const alternateGrade = alternateGrades?.[0];
  const handleDuplicate: MouseEventHandler<HTMLElement> | undefined =
    onDuplicate && alternateGrade
      ? (e) => {
          e.stopPropagation();
          onDuplicate(alternateGrade);
        }
      : undefined;
  const duplicateTooltip = `Duplicate card in ${alternateGrade?.gradeName ?? 'a different'} grade`;
  return (
    <div className={clsx(classes.root, className)} style={{ backgroundColor: color }}>
      {!!onToggleHidden && (
        <IconButton
          faIcon={isHidden ? faSquare : faCheck}
          title={isHidden ? 'Show' : 'Hide'}
          onClick={(e) => {
            e.stopPropagation();
            onToggleHidden();
          }}
          color="white"
          size={size}
        />
      )}
      {!!onDuplicate && (
        <IconButton
          size={size}
          color="white"
          faIcon={faCopy}
          title={duplicateTooltip}
          disabled={!handleDuplicate}
          onClick={handleDuplicate}
        />
      )}
      {!!onDelete && (
        <IconButton
          faIcon={faTrashCan}
          title="Delete"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          color="white"
          size={size}
        />
      )}
    </div>
  );
}
