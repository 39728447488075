import { useCallback, useState } from 'react';

function useFullScreenState(): [boolean, () => void];
function useFullScreenState(noFullScreen: boolean): [boolean, undefined | (() => void)];
function useFullScreenState(noFullScreen?: boolean): [boolean, undefined | (() => void)] {
  const [isFullScreen, setFullScreen] = useState(false);
  const toggleFullScreen = useCallback(() => setFullScreen((v) => !v), []);
  return [isFullScreen, !noFullScreen ? toggleFullScreen : undefined];
}

export default useFullScreenState;
