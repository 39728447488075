import React from 'react';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import { RenderStatsInTooltipFn, RenderTooltipTitleFn } from './types';
import useFormatStatValue from 'hooks/useFormatStatValue';

export interface BarChartTooltipProps<TItem extends any> {
  itemLabel: string | JSX.Element;
  renderStatsInTooltip?: RenderStatsInTooltipFn<TItem>;
  renderTooltipTitle?: RenderTooltipTitleFn<TItem>;
  item: TItem;
  color: string | undefined;
}

export default function BarChartTooltip<TItem extends any>({
  itemLabel,
  item,
  renderStatsInTooltip,
  color,
  renderTooltipTitle,
}: BarChartTooltipProps<TItem>) {
  const { formatStatValue } = useFormatStatValue();
  return (
    <ChartTooltip
      title={renderTooltipTitle?.(item, { color })}
      items={[
        {
          color,
          id: '1',
          label: itemLabel,
          stats: renderStatsInTooltip?.(item, { color }, formatStatValue),
        },
      ]}
    />
  );
}
