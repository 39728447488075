import { sortByStringField } from '../../../../utils/sort';
import { getISODatesInRange } from '../../../../sci-ui-components/utils/date';
import { PopulationChartDataPoint } from '../types';

export default function populationCountChartDataFromApi(
  apiItems: PopulationChartDataPoint[]
): PopulationChartDataPoint[] {
  if (!apiItems.length) {
    return [];
  }
  const sortedApiItems = sortByStringField(apiItems, (item) => item.date, 'asc');
  const apiItemsMap = new Map(apiItems.map((item) => [item.date, item]));

  const startDate = sortedApiItems[0].date;
  const endDate = sortedApiItems[sortedApiItems.length - 1].date;
  const allDates = getISODatesInRange([startDate, endDate]);

  return allDates.reduce<PopulationChartDataPoint[]>((acc, date) => {
    const point = apiItemsMap.get(date);
    const prevPoint = acc[acc.length - 1];
    acc.push(
      point ?? {
        count: prevPoint ? prevPoint.count : null,
        higher: prevPoint ? prevPoint.higher : null,
        date,
      }
    );
    return acc;
  }, []);
}
