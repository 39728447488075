import { formatISODate, parseUtcAsIfItIsLocal } from '../../../../sci-ui-components/utils/date';
import { CollectibleChartStats, CollectiblesChartStats } from '../../../../sci-ui-components/types/chartData';
import { ApiCollectibleChartStats, ApiCollectiblesChartStats } from '../../types';

export default function chartsStatsFromApi(apiItem: ApiCollectiblesChartStats): CollectiblesChartStats {
  return Object.entries(apiItem ?? {}).reduce<CollectiblesChartStats>((acc, [collectibleId, apiStats]) => {
    acc[Number(collectibleId)] = chartsCollectibleStatsFromApi(apiStats);
    return acc;
  }, {});
}

function chartsCollectibleStatsFromApi(apiItem: ApiCollectibleChartStats): CollectibleChartStats {
  const parsedDate = parseUtcAsIfItIsLocal(apiItem.lastSaleDate);
  const lastSaleDate = formatISODate(parsedDate);
  return {
    avgSalePrice: apiItem.avg,
    highSalePrice: apiItem.high,
    lowSalePrice: apiItem.low,
    marketCap: apiItem.marketCap,
    lastSaleDate,
    maxSalesCount: apiItem.maxCount,
    minSalesCount: apiItem.minCount,
    salePriceChange: apiItem.changeValue,
    salePriceChangePercentage: Number(apiItem.changePercent),
    salesCount: apiItem.count,
    totalSalesAmount: apiItem.total,

    startAvgSalePrice: apiItem.startAvg,
    startSalesCount: apiItem.startCount,

    last14AvgSalePrice: apiItem.last14Avg,
    last14SalesCount: apiItem.last14Count,
    last14TotalSalesAmount: apiItem.last14Total,

    endAvgSalePrice: apiItem.endAvg,
    endSalesCount: apiItem.endCount,
  };
}
