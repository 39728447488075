import { SportsCardPhoto } from '../../../../sci-ui-components/types/sportsCardPhoto';
import { ApiCardPhoto } from '../../types';

export default function cardPhotoFromApi(apiCardPhoto: ApiCardPhoto | null | undefined): SportsCardPhoto | null {
  return apiCardPhoto
    ? {
        cardNumber: apiCardPhoto.card_number,
        id: apiCardPhoto.id,
        imageUrl: apiCardPhoto.image_url,
        playerId: apiCardPhoto.player_id,
        setId: apiCardPhoto.set_id,
        specificQualifier: apiCardPhoto.specific_qualifier,
        variationId: apiCardPhoto.variation_id,
      }
    : null;
}
