import { ReactNode, useMemo } from 'react';
import BarChartUI from '../../../sci-ui-components/charts/BarChart/BarChart';
import { Collectible } from '../../../sci-ui-components/types/collectible';
import { ColorsMap } from '../../../sci-ui-components/utils/colors';
import { CollectibleChartStats, CollectiblesChartStats } from '../../../sci-ui-components/types/chartData';
import { plural } from '../../../sci-ui-components/utils/pluralize';
import { StatType } from '../../../sci-ui-components/types/statType';
import BarChartXAxisTick from './BarChartXAxisTick';
import { formatDate } from 'sci-ui-components/utils/date';
import { getSportsCardGrade, makeCollectibleDescription } from 'sci-ui-components/utils/collectibleDescription';
import useFormatStatValue, { FormatStatValueFn } from 'hooks/useFormatStatValue';

export type ChartValueField = keyof Omit<CollectibleChartStats, 'lastSaleDate'>;

interface CollectibleWithStats {
  collectible: Collectible;
  stats: CollectibleChartStats | null;
}

export default function BarChart({
  className,
  isLoading,
  header,
  highlightedCollectibleId,
  collectibleStats,
  chartValueField,
  chartValueType,
  collectibles,
  hiddenCollectiblesIds,
  colors,
  isFullScreen,
  toggleFullScreen,
  emptyMessage,
  height = 400,
  headerSectionClassName,
  chartsWrapperClassName,
}: {
  className?: string;
  isLoading: boolean;
  header: ReactNode;
  isFullScreen?: boolean;
  toggleFullScreen?: () => void;
  collectibles: Collectible[];
  hiddenCollectiblesIds?: number[];
  collectibleStats: CollectiblesChartStats | null;
  highlightedCollectibleId: number | undefined | null;
  colors: ColorsMap<number>;
  chartValueField: ChartValueField;
  chartValueType: StatType;
  emptyMessage: string;
  height?: number;
  headerSectionClassName?: string;
  chartsWrapperClassName?: string;
}) {
  const { formatStatValue } = useFormatStatValue();

  const collectiblesWithStats = useMemo(
    () =>
      collectibles.reduce<CollectibleWithStats[]>((acc, collectible) => {
        if (!hiddenCollectiblesIds?.includes(collectible.id)) {
          acc.push({
            collectible,
            stats: collectibleStats ? collectibleStats[collectible.id] : null,
          });
        }
        return acc;
      }, []),
    [collectibles, collectibleStats, hiddenCollectiblesIds]
  );

  const xAxisLabelsByCollectibleId = useMemo(
    () =>
      collectibles.reduce<Map<number, string>>((acc, collectible) => {
        const { fullDescription } = makeCollectibleDescription(collectible);
        const grade = getSportsCardGrade(collectible);
        const label = `${fullDescription}${grade ? ` ${grade}` : ''}`;
        acc.set(collectible.id, label);
        return acc;
      }, new Map()),
    [collectibles]
  );

  return (
    <BarChartUI<CollectibleWithStats, number>
      className={className}
      isLoading={isLoading}
      data={collectiblesWithStats || []}
      getXValue={({ collectible }) => collectible.id}
      getYValue={({ stats }) => (stats ? stats[chartValueField] : 0)}
      getColor={({ collectible }) => colors.get(collectible.id)}
      formatYAxisValue={(value) =>
        formatStatValue({
          value,
          type: chartValueType,
        })
      }
      formatXAxisValue={(collectibleId) => xAxisLabelsByCollectibleId.get(collectibleId) ?? String(collectibleId)}
      chartHeader={header}
      highlighDataKey={highlightedCollectibleId}
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
      height={height}
      renderStatsInTooltip={renderStatsInTooltip}
      renderTooltipTitle={renderTooltipTitle}
      renderLabel={(value) => renderLabel(value, chartValueType, formatStatValue)}
      renderXAxisTick={({ item, ...other }) => <BarChartXAxisTick {...other} collectible={item.collectible} />}
      emptyMessage={emptyMessage}
      chartsWrapperClassName={chartsWrapperClassName}
      headerSectionClassName={headerSectionClassName}
    />
  );
}

function renderTooltipTitle({ stats }: CollectibleWithStats) {
  if (!stats) {
    return null;
  }
  return `Last Sold: ${formatDate(stats.lastSaleDate)}`;
}

function renderLabel({ stats }: CollectibleWithStats, chartValueType: StatType, formatStatValue: FormatStatValueFn) {
  if (!stats) {
    return null;
  }
  switch (chartValueType) {
    case 'price':
      return `${formatStatValue({
        value: stats.endAvgSalePrice,
        type: chartValueType,
      })} (${formatDate(stats.lastSaleDate)})`;
    case 'count':
      return `${formatStatValue({
        value: stats.salesCount,
        type: chartValueType,
      })} Sales`;
    default:
      return `${formatStatValue({
        value: stats.endAvgSalePrice,
        type: chartValueType ?? 'price',
      })} (${formatDate(stats.lastSaleDate)})`;
  }
}

function renderStatsInTooltip(
  { stats }: CollectibleWithStats,
  {}: { color: string | undefined },
  formatStatValue: FormatStatValueFn
) {
  if (!stats) {
    return null;
  }
  return (
    <>
      <span>
        {formatStatValue({
          value: stats.salesCount,
          type: 'count',
        })}{' '}
        {plural(stats.salesCount, {
          one: 'Sale',
          other: 'Sales',
        })}
      </span>
      <span>
        Last Sale:{' '}
        {formatStatValue({
          value: stats.endAvgSalePrice,
          type: 'price',
        })}
        {` (${formatStatValue({
          value: stats.salePriceChangePercentage,
          type: 'percentage',
        })})`}
      </span>
    </>
  );
}
