import { ReactNode, useEffect, useState } from 'react';

/**
 *  NOTE: since we use data from local storage and query string,
 * rendering content based on this data with static generation approach will result in html mismatch errors
 * To prevent that, there are 2 options:
 *  1. use cookies instead oof LocalStorage and do server-side rendering (render on server on request)
 *  2. Do not render content that depends on that data on static generation (render on server at build time)
 * At the moment, we do option 2 as we are not worried about SEO and we do not need overhead of SSR for out restriced access SPA
 */
export default function ClientSideOnly({ children }: { children: ReactNode | ReactNode[] }) {
  const [clientSide, setClientSide] = useState(false);
  useEffect(() => setClientSide(true), []);

  if (!clientSide) {
    return null;
  }
  return <>{children}</>;
}
