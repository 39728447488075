import { CollectibleType } from 'sci-ui-components/types/collectibleType';

export default function getCollectibleTypeParam(collectibleType: CollectibleType): string {
  switch (collectibleType) {
    case 'sports-card': {
      return 'cards';
    }
    case 'sealed-wax-card': {
      return 'sealed-waxes';
    }
    default: {
      return collectibleType;
    }
  }
}
