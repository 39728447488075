import { useCallback, useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import clsx from 'clsx';
import { faRotateLeft, faRotateRight, faSpinner } from '@fortawesome/pro-light-svg-icons';

import 'cropperjs/dist/cropper.css';
import { Button } from '@sportscardinvestor/sci-ui/components/buttons/button';
import { Spinner } from '@sportscardinvestor/sci-ui/components/spinner';

export type ImageCropperProps = {
  className?: string;
  imageUrl: string | null;
  onSubmit: (imageDataUrl: string | null) => void;
  onCancel: () => void;
  formId?: string;
  aspectRatio?: number | null;
  autoCropArea?: number | null;
  isLoading?: boolean;
  initialCropShift?: InitialCropShift | null;
};

export interface InitialCropShift {
  topPercentage?: number;
}

export interface ImageCropperControls {
  getCroppedValue: () => string | null;
}

export default function ImageCropper({
  className,
  imageUrl,
  onSubmit,
  onCancel,
  formId,
  aspectRatio,
  autoCropArea,
  isLoading,
  initialCropShift,
}: ImageCropperProps) {
  const cropperElement = useRef<ReactCropperElement | null>(null);
  const handleRotate = (angleInDegrees: number) => {
    cropperElement.current?.cropper?.rotate(angleInDegrees);
  };
  const handleSubmit = useCallback(() => {
    const cropper = cropperElement?.current?.cropper;
    if (!cropper) {
      // NOTE: should never happen
      return;
    }
    try {
      const imageDataUrl = cropper.getCroppedCanvas()?.toDataURL() ?? null;
      onSubmit(imageDataUrl);
    } catch (err) {
      console.error(err);
    }
  }, [onSubmit]);

  const isLoadingWithImage = !!imageUrl && isLoading;

  return (
    <form
      id={formId}
      className={clsx(className, 'flex flex-col overflow-hidden')}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {!!isLoading && !imageUrl && <Spinner />}
      <Cropper
        key={imageUrl}
        ref={cropperElement}
        src={imageUrl ?? undefined}
        initialAspectRatio={aspectRatio ?? undefined}
        aspectRatio={aspectRatio ?? undefined}
        guides
        autoCropArea={autoCropArea ?? undefined}
        className="grow shrink overflow-hidden"
        ready={() => {
          const cropper = cropperElement?.current?.cropper;
          if (imageUrl && cropper && initialCropShift) {
            const cropBox = cropper.getCropBoxData();
            if (initialCropShift.topPercentage && cropBox.height) {
              const yShift = cropBox.height * initialCropShift.topPercentage;
              cropper.setCropBoxData({
                ...cropBox,
                top: cropBox.top + yShift,
              });
            }
          }
        }}
      />
      <div className="twp flex items-center justify-center gap-4 flex-wrap pt-2 grow-0 shrink-0">
        <Button
          size="medium"
          variant="default"
          shape="round"
          onClick={onCancel}
          className="basis-1 flex-grow md:max-w-40 mr-auto"
          isDisabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          size="medium"
          variant="default"
          shape="round"
          faIconLeft={faRotateLeft}
          onClick={() => handleRotate(-90)}
        >
          Rotate left
        </Button>
        <Button
          size="medium"
          variant="default"
          shape="round"
          faIconRight={faRotateRight}
          onClick={() => handleRotate(90)}
        >
          Rotate right
        </Button>
        <Button
          type="submit"
          size="medium"
          variant="primary"
          shape="round"
          faIconLeft={isLoadingWithImage ? faSpinner : undefined}
          iconLeftClassName={isLoadingWithImage ? 'animate-spin' : undefined}
          className="basis-1 flex-grow md:max-w-40 ml-auto"
          isDisabled={isLoadingWithImage || !imageUrl}
        >
          Use This
        </Button>
      </div>
    </form>
  );
}
