import { ReactNode, useMemo, useState } from 'react';
import { Tabs as AntTabs } from 'antd';
import clsx from 'clsx';
import classes from './Tabs.module.scss';
import { Breakpoint } from 'sci-ui-components/styles/breakpoints';
import { useBreakpoint } from 'sci-ui-components/hooks/useBreakpoint';
import SingleSelect, { Option } from 'sci-ui-components/forms/SingleSelect/SingleSelect';

const TabPane = AntTabs.TabPane;

export interface TabOption<TId extends string = string> {
  id: TId;
  title: ReactNode;
  content: ReactNode;
  isHidden?: boolean;
}

export type TabVariant = 'primary' | 'secondary';

export default function Tabs<TId extends string = string>({
  className,
  tabs,
  variant = 'primary',
  defaultTabId,
  onTabClick,
  destroyInactiveTab = false,
  activeKey,
  dropdownOnBreakpoints,
  fieldLabel,
}: {
  className?: string;
  variant?: TabVariant;
  tabs: TabOption<TId>[];
  defaultTabId?: TId;
  activeKey?: TId;
  onTabClick?: (activeKey: TId, e: React.KeyboardEvent | React.MouseEvent) => void;
  destroyInactiveTab?: boolean;
  dropdownOnBreakpoints?: Breakpoint[] | null;
  fieldLabel?: string;
}) {
  const breakpoint = useBreakpoint(true);
  const [selectedOption, setSelectedOption] = useState<TId | null>(activeKey || defaultTabId || null);

  const presetOptions = useMemo<Option<string>[]>(() => {
    return tabs.map<Option<string>>((tab) => ({
      label: tab.title as string,
      value: tab.id,
      disabled: false,
    }));
  }, [tabs]);

  if (dropdownOnBreakpoints && breakpoint && dropdownOnBreakpoints.find((item) => item === breakpoint)) {
    return (
      <>
        <SingleSelect
          options={presetOptions}
          notEmpty
          fieldLabel={fieldLabel}
          fieldLabelPlacement="inline"
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e as TId);
            if (!!e) {
              onTabClick?.(e as TId, {} as React.KeyboardEvent | React.MouseEvent);
            }
          }}
          className={classes.dropdown}
        />
        {tabs.find((tab) => tab.id === selectedOption)?.content}
      </>
    );
  }

  return (
    <AntTabs
      className={clsx(
        classes.root,
        {
          [classes.primary]: variant === 'primary',
          [classes.secondary]: variant === 'secondary',
        },
        className
      )}
      activeKey={activeKey}
      defaultActiveKey={defaultTabId}
      onTabClick={(tabId, e) => onTabClick?.(tabId as TId, e)}
      type="card"
      destroyInactiveTabPane={destroyInactiveTab}
    >
      {tabs.reduce<JSX.Element[]>((acc, { id, title, content, isHidden }) => {
        if (!isHidden) {
          acc.push(
            <TabPane tab={title} key={id} destroyInactiveTabPane={destroyInactiveTab}>
              {content}
            </TabPane>
          );
        }
        return acc;
      }, [])}
    </AntTabs>
  );
}
