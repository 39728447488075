import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import ChartsDialog from './ChartsDialog';
import { registerDialog, ChartsDialogProviderProps } from './index';
import { CollectibleTypeValueContextProvider } from 'features/collectibles/CollectibleTypeValueContext';

type State = Partial<ChartsDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function ChartsDialogProvider({ id }: { id: string }) {
  const [{ isOpen, collectibleIds, collectibleType, resolve, dateRange, customDateRange, specificPoints }, setState] =
    useState<State>({
      isOpen: false,
    });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          ...props,
          isOpen: true,
        });
      }),
    [id]
  );

  return (
    <CollectibleTypeValueContextProvider collectibleType={collectibleType!}>
      <ChartsDialog
        isOpen={isOpen}
        collectibleIds={collectibleIds!}
        collectibleType={collectibleType!}
        dateRange={dateRange}
        customDateRange={customDateRange}
        specificPoints={specificPoints}
        onClose={(hasGoneToCharts: boolean) => {
          setState((s) => ({ ...s, isOpen: false }));
          resolve?.(hasGoneToCharts);
        }}
      />
    </CollectibleTypeValueContextProvider>
  );
});
