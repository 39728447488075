import React, { useState, useMemo } from 'react';
import { Avatar } from 'antd';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getConfig from 'next/config';
import { faUser, faFolderGear, faArrowRightFromBracket, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { UserOutlined } from '@ant-design/icons';

import useAuth from '../../features/auth/useAuth';
import useUser from '../../features/user/useUser';
import SimpleCollapsible from '../SimpleCollapsible/SimpleCollapsible';

import classes from './UserAccountMenu.module.scss';
import useClickedOutside from 'hooks/useClickedOutside';
import useAccessFlags from 'hooks/useAccessFlags';
import { trackEvent } from '@/features/analytics/trackEvent';

const { publicRuntimeConfig } = getConfig();

const version = publicRuntimeConfig.version || false;
const build = publicRuntimeConfig.build || false;

const appBuildVersion = version && build ? `${publicRuntimeConfig.version} (Build ${publicRuntimeConfig.build})` : null;

const createMenuJSXActionItem = ({
  key,
  label,
  icon,
  action,
  className,
}: {
  key: string;
  label: string;
  icon: IconDefinition;
  className?: string;
  action: () => void;
}) => {
  return (
    <a key={key} className={clsx(classes.item, classes.action, className)} onClick={action}>
      <FontAwesomeIcon icon={icon} />
      <span className={classes.itemLabel} onClick={action}>
        {label}
      </span>
    </a>
  );
};

const createMenuJSXStaticItem = ({
  content,
  className,
  visible = true,
  key,
}: {
  content: React.ReactNode;
  className?: string;
  visible?: boolean;
  key: string;
}) => {
  return visible ? (
    <div key={key} className={clsx(classes.item, className)} onClick={(e) => e.stopPropagation()}>
      {content}
    </div>
  ) : null;
};

export default function UserAccountMenu() {
  const { initiateAuth, isLoggedIn, logout } = useAuth();
  const user = useUser();
  const [visible, setVisible] = useState(false);
  const userAccountMenuRef = useClickedOutside(visible, () => setVisible(!visible));
  const { push } = useRouter();
  const isUserPreferenceVisible = useAccessFlags({ flag: 'isUserPreferenceVisible' });

  const items = useMemo(() => {
    const items = [
      createMenuJSXStaticItem({ key: 'hi', content: <span className={classes.name}>Hi, {user.data?.name}</span> }),
      // TODO: Temporarily hide the "ADD/EDIT PROFILE PIC" option until functionality is implemented later.
      // createMenuJSXActionItem({ key: 'profile', label: 'add/edit profile pic', icon: faUserPlus, action: () => {} }),
      createMenuJSXActionItem({
        key: 'account',
        label: 'manage account',
        icon: faUser,
        action: () => {
          const manageAccountURL = 'https://www.sportscardinvestor.com/account/';
          window.open(manageAccountURL, 'blank');
          trackEvent({
            eventName: 'USER_ACCOUNT_MENU_ITEM_CLICKED',
            menuItem: 'manage account',
            linkURL: manageAccountURL,
          });
        },
      }),
      createMenuJSXActionItem({
        key: 'auth',
        label: isLoggedIn ? 'sign out' : 'login',
        icon: faArrowRightFromBracket,
        action: () => {
          if (isLoggedIn) {
            logout();
            trackEvent({
              eventName: 'USER_ACCOUNT_MENU_ITEM_CLICKED',
              menuItem: 'sign out',
            });
          } else {
            initiateAuth();
            trackEvent({
              eventName: 'USER_ACCOUNT_MENU_ITEM_CLICKED',
              menuItem: 'login',
            });
          }
        },
      }),
      createMenuJSXStaticItem({
        key: 'membership',
        visible: !!user.data?.membershipTier || !!appBuildVersion,
        content: (
          <div>
            <div className={classes.separator}></div>
            <div className={classes.appDetailsContent}>
              {!!user.data?.membershipTier && (
                <p>
                  Membership: <span className={classes.tierName}>{user.data?.membershipTier}</span>
                </p>
              )}
              {!!appBuildVersion && (
                <p>
                  Version:
                  <a className={classes.version} href="https://marketmoversapp.com/release-notes/">
                    {appBuildVersion}
                  </a>
                </p>
              )}
            </div>
          </div>
        ),
      }),
    ];
    if (isUserPreferenceVisible) {
      items.splice(
        2,
        0,
        createMenuJSXActionItem({
          key: 'user-preferences',
          label: 'user preferences',
          icon: faFolderGear,
          action: () => {
            push('/user/preferences');
            trackEvent({
              eventName: 'USER_ACCOUNT_MENU_ITEM_CLICKED',
              menuItem: 'user preferences',
            });
          },
        })
      );
    }
    return items;
  }, [initiateAuth, isUserPreferenceVisible, push, isLoggedIn, logout, user.data?.membershipTier, user.data?.name]);

  return (
    <div ref={userAccountMenuRef} className={classes.profileMenu}>
      <button
        className={classes.profileIcon}
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <Avatar icon={<UserOutlined />} className={classes.icon} />
      </button>
      <SimpleCollapsible isExpanded={visible} className={classes.content}>
        {items}
      </SimpleCollapsible>
    </div>
  );
}
