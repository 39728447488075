import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
  BarChart as BarReChart,
  Cell,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  YAxisProps,
  XAxisProps,
  Text,
  Tooltip,
  LabelList,
} from 'recharts';
import { formatAsCount, formatAsString } from '../utils/formatters';
import ChartWrapper, { ChartWrapperProps } from '../ChartWrapper/ChartWrapper';
import BarChartTooltip from './BarChartTooltip';
import { RenderStatsInTooltipFn, RenderTooltipTitleFn, RenderXAxisTickFn } from './types';

import classes from './BarChart.module.scss';
import { isDefined } from 'sci-ui-components/utils/misc';

export type BarChartProps<TItem extends object, TXAxisValue extends string | number = string> = ChartWrapperProps & {
  data: TItem[];
  getYValue: (item: TItem) => number;
  getXValue: (item: TItem) => TXAxisValue;
  getColor: (item: TItem) => string | undefined;

  highlighDataKey?: TXAxisValue | null;

  formatXAxisValue?: (value: TXAxisValue) => string | JSX.Element;
  formatYAxisValue?: (value: number) => string;
  yAxisProps?: Partial<YAxisProps>;
  xAxisProps?: Partial<XAxisProps>;
  renderStatsInTooltip?: RenderStatsInTooltipFn<TItem>;
  renderTooltipTitle?: RenderTooltipTitleFn<TItem>;
  renderLabel?: (item: TItem) => ReactNode;
  renderXAxisTick?: RenderXAxisTickFn<TItem> | boolean;
  xAxisHeight?: number;
  emptyMessage?: string;
  chartsWrapperClassName?: string;
};

export default function BarChart<TItem extends object, TXAxisValue extends string | number = string>({
  data,
  highlighDataKey,
  formatXAxisValue = formatAsString,
  formatYAxisValue = formatAsCount,
  yAxisProps = {},
  xAxisProps = {},
  getColor,
  getXValue,
  getYValue,
  renderStatsInTooltip,
  renderTooltipTitle,
  renderXAxisTick,
  renderLabel,
  emptyMessage,
  xAxisHeight = 120,
  chartsWrapperClassName,
  ...wrapperProps
}: BarChartProps<TItem, TXAxisValue>) {
  const isEmpty = !data?.length;
  const renderCustomXAxisTick =
    typeof renderXAxisTick === 'boolean'
      ? renderXAxisTick
      : ({
          x,
          y,
          payload,
          fill,
          index,
        }: {
          fill: string | undefined;
          x: number;
          y: number;
          index: number;
          payload: {
            value: TXAxisValue;
          };
        }) => {
          const item = data[index];
          if (renderXAxisTick && !!item) {
            return renderXAxisTick({
              x,
              y,
              item,
              color: fill,
            });
          }
          return (
            <Text width={180} x={x} y={y} textAnchor="middle" verticalAnchor="start">
              {formatAsString(payload?.value)}
            </Text>
          );
        };
  return (
    <>
      <ChartWrapper {...wrapperProps} className={chartsWrapperClassName} isEmpty={isEmpty} emptyMessage={emptyMessage}>
        <BarReChart className={classes.chart} data={data}>
          <CartesianGrid vertical={false} stroke="var(--gull-gray)" />
          <XAxis
            dataKey={getXValue}
            height={xAxisHeight}
            axisLine={false}
            tickLine={false}
            tickMargin={12}
            tick={renderCustomXAxisTick}
            interval={0}
            {...xAxisProps}
          />
          <YAxis
            axisLine={false}
            tickLine={{ stroke: 'var(--gull-gray)' }}
            tickFormatter={formatYAxisValue}
            width={72}
            {...yAxisProps}
          />
          <Tooltip<TXAxisValue, string>
            cursor={{ fill: 'var(--concrete-opaque)' }}
            content={(tooltipProps) => {
              const chartPayload = (tooltipProps?.payload ?? [])[0];
              const item: TItem = chartPayload?.payload;
              if (!item) {
                return null;
              }
              const value = getXValue(item);
              if (typeof value === 'undefined') {
                return null;
              }
              return (
                <BarChartTooltip
                  itemLabel={formatXAxisValue(value)}
                  item={item}
                  color={getColor(item)}
                  renderStatsInTooltip={renderStatsInTooltip}
                  renderTooltipTitle={renderTooltipTitle}
                />
              );
            }}
          />
          <Bar key="bar" dataKey={(item) => getYValue(item)} maxBarSize={220}>
            {data?.map((item) => {
              const labelKey = getXValue(item);
              const isInFocus = highlighDataKey === labelKey;
              const isOutOfFocus = isDefined(highlighDataKey) && !isInFocus;
              const color = getColor(item);
              return (
                <Cell
                  key={labelKey}
                  stroke={color}
                  fill={color}
                  className={clsx({
                    [classes.bar]: true,
                    [classes.outOfFocus]: isOutOfFocus,
                  })}
                  id={String(labelKey)}
                />
              );
            })}
            {!!renderLabel && (
              <LabelList
                dataKey={(item: TItem) => {
                  return renderLabel(item);
                }}
                position="inside"
                fill="var(--white)"
                stroke="transparent"
              />
            )}
          </Bar>
        </BarReChart>
      </ChartWrapper>
    </>
  );
}
