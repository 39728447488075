import { useCallback, useEffect, useMemo } from 'react';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import { trackEvent } from '../analytics/trackEvent';
import { getNumberOfDaysInDateRange, IsoDateRange } from 'sci-ui-components/utils/date';
import { showInfo } from 'services/toaster';

const storageKey = 'sci_chart_stgs';

export interface ChartSettings {
  showTrend: ShowTrendOption;
  yBaseline: YBaselineOption;
  groupBy: GroupOption;
  groupByOptions: GroupByOption[];
}
interface GroupByOption {
  value: GroupOption;
  isDisabled?: boolean;
}
const defaultSettings: ChartSettings = {
  showTrend: 'both',
  yBaseline: 'zero',
  groupBy: 'day',
  groupByOptions: [{ value: 'day' }, { value: 'week' }, { value: 'month' }],
};

export const showTrendOptions = ['trend', 'line', 'both'] as const;
export type ShowTrendOption = (typeof showTrendOptions)[number];
export const yBaselineOptions = ['zero', 'min'] as const;
export type YBaselineOption = (typeof yBaselineOptions)[number];
export const groupOptions = ['day', 'week', 'month'] as const;
export type GroupOption = (typeof groupOptions)[number];
export type SetTrendFn = (trendOption: ShowTrendOption) => void;
export type SetYBaselineFn = (yBaselineOption: YBaselineOption) => void;
export type SetGroupByFn = (groupBy: GroupOption) => void;

const groupByDayMaxDateRangeInDays = 365;
const groupByWeekMaxDateRangeInDays = groupByDayMaxDateRangeInDays * 7;

export default function useChartSettings({ dateRange }: { dateRange: IsoDateRange | null }) {
  const [value, setValue] = useLocalStorageValue<ChartSettings>(storageKey, true);
  const chartSettings = useMemo<ChartSettings>(() => {
    const groupByOptions = getGroupByoptions({ dateRange });
    const enabledGroupByOptions = groupByOptions.filter((o) => !o.isDisabled);
    const activeGroupByOption =
      enabledGroupByOptions.find((o) => o.value === value?.groupBy) ?? enabledGroupByOptions[0];
    return {
      ...defaultSettings,
      showTrend: value && showTrendOptions.includes(value?.showTrend) ? value.showTrend : defaultSettings.showTrend,
      yBaseline: value && yBaselineOptions.includes(value?.yBaseline) ? value.yBaseline : defaultSettings.yBaseline,
      groupBy: activeGroupByOption?.value ?? defaultSettings?.groupBy,
      groupByOptions,
    };
  }, [value, dateRange]);

  useEffect(() => {
    if (!!value?.groupBy && chartSettings.groupBy !== value.groupBy) {
      showInfo({
        message: `Showing results by ${chartSettings.groupBy}`,
        description: `Please decrease date range to view results by ${value.groupBy}`,
      });
    }
  }, [chartSettings.groupBy, value?.groupBy]);

  const setSettings = useCallback(
    (newValue: ChartSettings) => {
      setValue(newValue);
      trackEvent({
        eventName: 'CHARTS_SETTINGS_CHANGED',
        settings: newValue,
      });
    },
    [setValue]
  );

  const setTrend = useCallback<SetTrendFn>(
    (trendOption) => {
      return setSettings({
        ...chartSettings,
        showTrend: trendOption,
      });
    },
    [chartSettings, setSettings]
  );
  const setYBaseline = useCallback<SetYBaselineFn>(
    (yBaselineOption) => {
      return setSettings({
        ...chartSettings,
        yBaseline: yBaselineOption,
      });
    },
    [chartSettings, setSettings]
  );
  const setGroupBy = useCallback<SetGroupByFn>(
    (groupBy) => {
      return setSettings({
        ...chartSettings,
        groupBy,
      });
    },
    [chartSettings, setSettings]
  );

  return {
    chartSettings,
    setSettings,
    setYBaseline,
    setTrend,
    setGroupBy,
  };
}

function getGroupByoptions({ dateRange }: { dateRange?: IsoDateRange | null }) {
  const daysInRange = dateRange ? getNumberOfDaysInDateRange(dateRange) : 0;
  const groupByOptions: GroupByOption[] = daysInRange
    ? defaultSettings.groupByOptions.map<GroupByOption>((option) => {
        switch (option.value) {
          case 'day':
            return { value: option.value, isDisabled: daysInRange > groupByDayMaxDateRangeInDays };
          case 'week':
            return { value: option.value, isDisabled: daysInRange > groupByWeekMaxDateRangeInDays };
          default:
            return option;
        }
      })
    : defaultSettings.groupByOptions;
  return groupByOptions;
}
