import { Collectible, isSportsCardCollectible } from '../../../sci-ui-components/types/collectible';
import {
  getSportsCardGrade,
  makeCollectibleDescription,
} from '../../../sci-ui-components/utils/collectibleDescription';
import classes from './Charts.module.scss';
import CollectibleImage from 'sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import GradeIndicator from 'sci-ui-components/sport-cards/GradeIndicator/GradeIndicator';

interface Props {
  x: number;
  y: number;
  collectible: Collectible;
}

export default function BarChartXAxisTick({ collectible, x, y }: Props) {
  const { fullDescription } = makeCollectibleDescription(collectible);
  let label = (
    <CollectibleImage
      collectibleType={collectible.collectibleType}
      url={collectible?.imageUrl}
      alt={fullDescription}
      size="small"
    />
  );
  if (isSportsCardCollectible(collectible)) {
    const collectibleGrade = getSportsCardGrade(collectible);
    if (collectibleGrade) {
      label = (
        <div className={classes.customLabel}>
          <CollectibleImage
            className={classes.customLabelImage}
            collectibleType={collectible.collectibleType}
            url={collectible?.imageUrl}
            alt={fullDescription}
            size="small"
          />
          <GradeIndicator grade={collectibleGrade} />
        </div>
      );
    }
  }

  const customLabelWidth = 55;
  const customLabelOffset = 27.5; // Centers the custom label under the bar in the chart
  return (
    <foreignObject x={x - customLabelOffset} y={y} width={customLabelWidth} height="60" className={classes.customXAxis}>
      {label}
    </foreignObject>
  );
}
