import React, { ReactNode, useEffect } from 'react';
import AppSkeleton from '../sci-ui-components/AppSkeleton/AppSkeleton';
import useAuth from '../features/auth/useAuth';

interface Props {
  skeleton?: React.FunctionComponent<any>;
  children: ReactNode;
  disabled?: boolean;
}

export default function AuthenticationGuard({ children, skeleton: Skeleton, disabled = false }: Props) {
  const { initiateAuth, isLoggedIn, isLoggingIn } = useAuth();

  useEffect(() => {
    if (!isLoggingIn && !isLoggedIn && !disabled) {
      initiateAuth();
    }
  }, [isLoggingIn, isLoggedIn, initiateAuth, disabled]);

  if (isLoggingIn) {
    return Skeleton ? <Skeleton /> : <AppSkeleton />;
  }

  if (!isLoggedIn && !disabled) {
    return null;
  }

  return <>{children}</>;
}
