/**
 * Splits arrray into N sub-arrays equaly (as possible)
 * @param items
 * @param numberOfGroups
 * @returns
 */
export default function splitArrayIntoColumns<TItem = any>(items: TItem[], numberOfGroups: number): TItem[][] {
  if (numberOfGroups === 1) {
    return [items];
  }
  return items.reduce<{ groups: TItem[][]; groupIndex: number }>(
    ({ groupIndex, groups }, item) => {
      groups[groupIndex].push(item);
      return {
        groups,
        groupIndex: groupIndex < numberOfGroups - 1 ? groupIndex + 1 : 0,
      };
    },
    {
      groups: Array.from({ length: numberOfGroups }).map(() => []),
      groupIndex: 0,
    }
  ).groups;
}
