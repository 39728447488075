import { useId } from 'react';
import { Modal, ModalProps } from 'antd';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-thin-svg-icons';
import { PopupContainerProvider } from '../forms/SimpleSelect/useSelectPopupContainer';
import Button from '../buttons/Button/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import classes from './ModalDialog.module.scss';

export type ModalVariant = 'primary' | 'secondary' | 'alternative';
export type ModalDialogProps = Omit<ModalProps, 'closeIcon'> & {
  titleAlign?: 'right' | 'center';
  variant?: ModalVariant;
  dense?: boolean;
  id?: string;
  isLoading?: boolean;
  withHeaderUnderline?: boolean;
};

export default function ModalDialog({
  className,
  footer,
  okText = 'Ok',
  cancelText = 'Cancel',
  onOk,
  okButtonProps = {},
  onCancel,
  cancelButtonProps = {},
  title = null,
  titleAlign,
  variant = 'primary',
  dense = false,
  id: idProp,
  children,
  isLoading,
  ...otherProps
}: ModalDialogProps) {
  const randomId = useId();
  const id = idProp ?? randomId;
  return (
    <PopupContainerProvider popupContainerId={id}>
      <Modal
        destroyOnClose
        className={clsx(
          classes.root,
          {
            [classes.primary]: variant === 'primary',
            [classes.secondary]: variant === 'secondary',
            [classes.alternative]: variant === 'alternative',
            [classes.dense]: dense,
            [classes.notDense]: !dense,
          },
          'relative',
          className
        )}
        {...otherProps}
        wrapProps={{
          id,
        }}
        title={
          <h5
            className={clsx(classes.titleContainer, {
              [classes.titleRight]: titleAlign === 'right',
              [classes.titleCenter]: titleAlign === 'center',
            })}
          >
            {title}
          </h5>
        }
        footer={
          typeof footer !== 'undefined' ? (
            footer
          ) : (
            <div className={classes.footer}>
              <Button
                {...cancelButtonProps}
                className={clsx(classes.footerButton, cancelButtonProps.className)}
                key="cancel"
                type="ghost"
                onClick={(e) => onCancel?.(e)}
              >
                {cancelText}
              </Button>
              <Button
                {...okButtonProps}
                className={clsx(classes.footerButton, okButtonProps.className)}
                key="ok"
                type="primary"
                onClick={(e) => onOk?.(e)}
              >
                {okText}
              </Button>
            </div>
          )
        }
        onCancel={onCancel}
        onOk={onOk}
        closeIcon={<FontAwesomeIcon icon={faXmark} className={classes.closeIcon} />}
      >
        {children}
        {isLoading ? (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-lightBg-inverse/50">
            <LoadingSpinner block size="large" />
          </div>
        ) : null}
      </Modal>
    </PopupContainerProvider>
  );
}
