import { CardSetVariation } from '../../../../sci-ui-components/types/cardSetVariation';
import { ApiCardSetVariation } from '../../types';

export default function cardSetVariationFromApi(apiSetVariation: ApiCardSetVariation): CardSetVariation {
  return {
    id: apiSetVariation.id,
    packOdds: apiSetVariation.pack_odds,
    printRun: apiSetVariation.print_run,
    query: apiSetVariation.query,
    setId: apiSetVariation.set_id,
    variationId: apiSetVariation.variation_id,
    variationType: apiSetVariation.variation_type,
  };
}
